import { Injectable } from '@angular/core';
import { Auth } from '../models/auth';
import { ServiceObject } from '../models/service-object';
import { WebAPIService } from './web-api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public user: any;
  public token: any;

  constructor(private webAPI: WebAPIService) {
  }

  login(user: any) {
    this.user = user;
  }

  logout() {
    localStorage.clear();
    this.user = null;
  }

  saveToken(data: any): void {
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('token_type', data.token_type);
    localStorage.setItem('expires_in', data.expires_in);
    this.saveUser();
  }

  getToken(): Auth {
    this.token = {
      access_token: localStorage.getItem('access_token'),
      token_type: localStorage.getItem('token_type'),
      expires_in: localStorage.getItem('expires_in'),
    };
    return this.token;
  }

  saveUser() {
    var servObj = new ServiceObject("user/auth/roles");
    this.webAPI.GetAction(servObj)
      .then(x => {
        servObj = <ServiceObject>x;
        if (!servObj.status)
          throw new Error(servObj.message);

        localStorage.setItem('user', JSON.stringify(servObj.data.user));
        this.GetUser();
      })
      .catch(x => {
        throw x.message;
      });
  }

  GetUser(): any {
    if (localStorage.getItem('user')) {
      var user = localStorage.getItem('user');
      this.user = JSON.parse(user ? user : '')[0];
    }
    return this.user;
  }
}
