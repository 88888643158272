<div class="container-fluid">
    <div class="row mt-4 mb-4">
        <div class="card-col col-12">
            <div class="card mb-2 mt-2 d-flex card-main">
                <div class="card-body">
                    <div class="row">
                        <div class="col-2 col-sm-2 col-md-1">
                            <button type="button" class="btn btn-lg mb-1" class="btn btn-lg mb-1 btn-outline-success">
                                <h1><i class="fas fa-calendar-check"></i></h1>
                            </button>
                        </div>
                        <div class="col-10 col-sm-10 col-md-11 justify-content-center align-self-center">
                            <h3>Casos por día</h3>
                        </div>
                        <div class="mt-4 mb-4 col-12">
                            <label class="form-label">Seleccione una fecha</label>
                            <input type="date" class="form-select" (change)="onChange($event)">
                        </div>
                        <div class="mt-4 mb-4 col-12" *ngIf="isLoading">
                            <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels"
                                [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend"
                                [chartType]="barChartType">
                            </canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>