<app-my-result [number]="tickets.length"></app-my-result>
<div class="container-fluid">
    <div class="row mt-4 mb-4">
        <div class="card-col col-12">
            <div class="card mb-2 mt-2 d-flex" *ngFor="let item of tickets"
                [ngClass]="'border-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'success')">
                <div class="card-body">
                    <!-- info ticket -->
                    <div class="row">
                        <!-- <div class="col-6 col-sm-6 col-md-1 justify-content-center align-self-center text-center">
                        <input class="form-check-input" type="checkbox">
                    </div> -->
                        <div class="col-6 col-sm-6 col-md-2 justify-content-center align-self-center text-center">
                            <button type="button" class="btn btn-lg"
                                [ngClass]="'btn-outline-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'success')"
                                (click)="open(item)">PF-{{item.id}}</button>
                        </div>
                        <div class="col-12 col-sm-9 col-md-7">
                            <!-- <span class="badge"
                                [ngClass]="'bg-' + (item.statuses.length > 0 ? item.statuses[item.statuses.length-1].css_class : 'success')">{{item.statuses.length
                                > 0 ? item.statuses[item.statuses.length-1].name : 'Sin Revisar'}}</span><br> -->
                            <small>{{item.message}}</small>
                            <h6>
                                <i class="fas fa-envelope"></i>
                                {{item.names}} -
                                <small class="text-muted">{{ item.created_at | diffDays: currentDate }}</small>
                            </h6>
                        </div>
                        <div class="col-12 col-sm-3 mt-1">
                            <span class="mt-1"><i class="fas fa-bell"></i> {{item.ticket_priority.name}}</span><br>
                            <span class="mt-1"><i class="fas fa-folder"></i> {{item.group.name}}</span><br>
                            <span class="mt-1"><i class="fas fa-thermometer-three-quarters"></i>
                                {{item.ticket_origin.name}}</span>
                        </div>
                    </div>
                    <!-- end info ticket -->
                </div>
            </div>
        </div>
    </div>
</div>