import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceObject } from '../models/service-object';
import { WebAPIService } from '../services/web-api.service';
import { TicketsFormComponent } from '../tickets-form/tickets-form.component';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent implements OnInit {

  tickets: any = [];
  currentDate!: string;

  constructor(private webAPI: WebAPIService, private modalService: NgbModal) { }

  ngOnInit(): void {
    var today = new Date();
    this.currentDate = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    var servObj = new ServiceObject("ticket");
    var paramsMain = new HttpParams();
    paramsMain = paramsMain.append("ticket_type_id", "2");
    this.webAPI.GetAction(servObj, paramsMain)
      .then(x => {
        servObj = <ServiceObject>x;
        this.tickets = x.data.tickets;
      }).catch(x => { throw x.message; });
  }

  open(item: any) {
    const modalRef = this.modalService.open(TicketsFormComponent, { size: 'lg', backdrop: 'static' });
    modalRef.componentInstance.item = item;
    modalRef.componentInstance.onlyRead = true;
    modalRef.componentInstance.currentDate = this.currentDate;
  }

}
