import { Component, OnInit } from '@angular/core';
import { ServiceObject } from 'src/app/models/service-object';
import { WebAPIService } from 'src/app/services/web-api.service';

@Component({
  selector: 'app-avg-report',
  templateUrl: './avg-report.component.html',
  styleUrls: ['./avg-report.component.scss']
})
export class AvgReportComponent implements OnInit {

  isLoading: boolean = false;
  seconds!: string;
  minutes!: string;
  days!: string;

  constructor(private webAPI: WebAPIService) { }

  ngOnInit(): void {
    this.isLoading = false;
    var servObj = new ServiceObject("report/avg");
    this.webAPI.GetAction(servObj)
      .then(x => {
        servObj = <ServiceObject>x;
        this.seconds = servObj.data.report[0].Seconds;
        this.minutes = servObj.data.report[0].Minutes;
        this.days = servObj.data.report[0].Days;
        this.isLoading = true;
      }).catch(x => { throw x.message; });
  }
}
