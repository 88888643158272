<app-my-result [number]="tickets.length"></app-my-result>
<div class="container-fluid">
  <div class="row mt-4 mb-4">
    <div class="card-col col-12 col-sm-12 col-md-12 col-lg-8">
      <div
        class="card mb-2 mt-2 d-flex"
        *ngFor="
          let item of tickets
            | paginate : { itemsPerPage: itemsPerPage, currentPage: p }
        "
        [ngClass]="
          'border-' +
          (item.statuses.length > 0
            ? item.statuses[item.statuses.length - 1].css_class
            : 'danger')
        "
      >
        <div class="card-body">
          <!-- info ticket -->
          <div class="row">
            <!-- <div class="col-6 col-sm-6 col-md-1 justify-content-center align-self-center text-center">
                            <input class="form-check-input" type="checkbox">
                        </div> -->
            <div
              class="col-6 col-sm-6 col-md-2 justify-content-center align-self-center text-center"
            >
              <button
                type="button"
                class="btn btn-lg"
                [ngClass]="
                  'btn-outline-' +
                  (item.statuses.length > 0
                    ? item.statuses[item.statuses.length - 1].css_class
                    : 'danger')
                "
                (click)="open(item)"
              >
                {{ item.names | shortName }}
              </button>
            </div>
            <div class="col-12 col-sm-9 col-md-7">
              <span
                class="badge"
                [ngClass]="
                  'bg-' +
                  (item.statuses.length > 0
                    ? item.statuses[item.statuses.length - 1].css_class
                    : 'danger')
                "
                >{{
                  item.statuses.length > 0
                    ? item.statuses[item.statuses.length - 1].name
                    : "Sin Revisar"
                }}</span
              ><br />
              <small>{{ item.message }} - #{{ item.id }}</small>
              <h6>
                <i class="fas fa-envelope"></i>
                {{ item.names }} -
                <small class="text-muted">{{
                  item.created_at | diffDays : currentDate
                }}</small>
              </h6>
              <span class="mt-1" style="color: #dc3545" *ngIf="item.phone"
                ><i class="fas fa-bell"></i> Contactar:
                {{ item.datetime_call | date : "dd/MM/yyyy h:mm a" }}</span
              ><br />
            </div>
            <div class="col-12 col-sm-3 mt-1">
              <span class="mt-1"
                ><i class="fas fa-bell"></i>
                {{ item.ticket_priority.name }}</span
              ><br />
              <span class="mt-1"
                ><i class="fas fa-folder"></i> {{ item.group.name }}</span
              ><br />
              <span class="mt-1"
                ><i class="fas fa-thermometer-three-quarters"></i>
                {{ item.ticket_origin.name }}</span
              >
            </div>
          </div>
          <!-- end info ticket -->
        </div>
      </div>
    </div>
    <div
      class="card-col col-12 col-sm-12 col-md-12 col-lg-4 order-first order-lg-last"
    >
      <div class="card m-3">
        <div class="card-body">
          <h5><i class="fas fa-search"></i> Filtros</h5>
          <form [formGroup]="searchForm" (ngSubmit)="applyFilters()">
            <div class="mb-3">
              <label class="form-label">Ticket ID</label>
              <input type="number" class="form-control" formControlName="id" />
            </div>
            <div class="mb-3">
              <label class="form-label">Nombres / Email del Peticionario</label>
              <input type="text" class="form-control" formControlName="names" />
            </div>
            <div class="mb-3">
              <label class="form-label">Estado</label>
              <select class="form-select" formControlName="state">
                <option value="0">Todos</option>
                <option value="100">Sin Revisar</option>
                <option *ngFor="let item of status" [value]="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label class="form-label">Asignado a</label>
              <select class="form-select" formControlName="user">
                <option value="0">Todos</option>
                <option
                  *ngFor="let item of users"
                  [value]="item.roles[0].pivot.id"
                >
                  {{ item.firstname }} {{ item.lastname }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label class="form-label">Prioridad</label>
              <select class="form-select" formControlName="priority">
                <option value="0">Todos</option>
                <option *ngFor="let item of priority" [value]="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <!-- <div class="mb-3">
                            <label class="form-label">Creado</label>
                            <select class="form-select" formControlName="date">
                                <option value="0">Todos</option>
                                <option>Hoy</option>
                                <option>Esta semana</option>
                                <option>Mes completo</option>
                                <option>Todos</option>
                            </select>
                        </div> -->
            <div class="d-grid gap-2">
              <button class="btn btn-success" type="submit">Filtrar</button>
            </div>
          </form>
          <div class="d-grid gap-2 mt-2">
            <button class="btn btn-warning" (click)="GetTickets()">
              Refrescar
            </button>
          </div>
          <div class="d-grid gap-2 mt-2" *ngIf="user && user.roles[0].id !== 4">
            <button class="btn btn-danger" (click)="createTicket()">
              Crear Ticket
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <div class="pagination-controls col-12">
        <label for="itemsPerPage">Tickets por página:</label>
        <select
          id="itemsPerPage"
          (change)="onItemsPerPageChange($event)"
          [value]="itemsPerPage"
        >
          <option *ngFor="let option of [10, 20, 30, 50]" [value]="option">
            {{ option }}
          </option>
        </select>
      </div>

      <div class="pagination">
        <button
          [disabled]="currentPage === 1"
          (click)="onPageChange(currentPage - 1)"
        >
          Anterior
        </button>

        <ng-container *ngFor="let page of getPagesToShow()">
          <button
            *ngIf="page"
            [class.active]="page === currentPage"
            [attr.disabled]="page === currentPage ? true : null"
            (click)="onPageChange(page)"
          >
            {{ page }}
          </button>
          <span *ngIf="!page">...</span>
        </ng-container>

        <button
          [disabled]="currentPage === totalPages()"
          (click)="onPageChange(currentPage + 1)"
        >
          Siguiente
        </button>
      </div>
    </div>
  </div>
</div>
