<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="alert alert-success" role="alert" *ngIf="number>0">
                <a class="alert-link" style="text-decoration: auto;">({{number}})</a> resultados.
            </div>
            <div class="alert alert-warning" role="alert" *ngIf="number==0">
                <a class="alert-link" style="text-decoration: auto;">({{number}})</a> resultados.
            </div>
        </div>
    </div>
</div>