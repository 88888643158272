import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/services/notification.service';
import { ServiceObject } from 'src/app/models/service-object';
import { WebAPIService } from 'src/app/services/web-api.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { AuthService } from 'src/app/services/auth.service';
import { TicketReportResponse } from './interfaces/ticker-report';
import { environment } from 'src/environments/environment';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-tickets-report',
  templateUrl: './tickets-report.component.html',
  styleUrls: ['./tickets-report.component.scss'],
})
export class TicketsReportComponent implements OnInit {
  form!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private toast: NotificationService,
    private webAPI: WebAPIService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    var today = new Date();
    this.form = this.formBuilder.group({
      start: ['', Validators.compose([Validators.required])],
      end: ['', Validators.compose([Validators.required])],
    });
  }

  Save() {
    if (this.form.invalid) {
      this.toast.showWarning('Todos los campos son obligatorios*', '');
    } else if (this.form.controls.end.value < this.form.controls.start.value) {
      this.toast.showWarning('Revise las fechas', '');
    } else {
      this.toast.showSuccess('Buscando...', '');
      var servObj = new ServiceObject(
        'report/ticketsByDays/' +
          this.form.controls.start.value +
          '/' +
          this.form.controls.end.value
      );
      this.webAPI
        .GetAction(servObj)
        .then((x) => {
          const response = x as TicketReportResponse;

          this.toast.showSuccess(
            'Se encontraron ' + response.data.count + ' registros',
            ''
          );
          this.downloadFile(response.data.download_link);
        })
        .catch((x) => {
          throw x.message;
        });
    }
  }

  downloadFileXLSX(data: any) {
    let group =
      this.authService.GetUser().user_role[0].user_role_group[0].group_id;
    let header = Object.keys(data[0]);
    if (group == 4) {
      header = header.filter((item) => item != 'AGENTE');
      header = header.filter((item) => item != 'RESPONSABLE_ACTUAL');
    }

    const filteredData = data.map((row: { [x: string]: any }) => {
      const filteredRow: any = {};
      header.forEach((fieldName) => {
        filteredRow[fieldName] = row[fieldName] != null ? row[fieldName] : '';
      });
      return filteredRow;
    });

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredData, {
      header,
    });
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, 'myFile');
  }

  async downloadFile(download_link: string) {
    try {
      const removedStorage = download_link.replace('/storage/', '');
      const downloadLink = `${environment.storage}${removedStorage}`;

      const link = document.createElement('a');
      link.href = downloadLink;
      link.download = downloadLink;
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
    }
  }

  downloadFileCsv(data: any) {
    const replacer = (key: any, value: null) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map((row: { [x: string]: any }) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(';')
    );
    csv.unshift(header.join(';'));
    var BOM = '\uFEFF';
    let csvArray = csv.join('\r\n');
    csvArray = BOM + csvArray;
    var blob = new Blob([csvArray], { type: 'text/csv;charset=utf-8' });
    FileSaver.saveAs(blob, 'myFile.csv');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
