<div class="container-fluid">
    <div class="row mt-4 mb-4">
        <div class="card-col col-12" *ngFor="let item of filteredReports">
            <div class="card mb-2 mt-2 d-flex card-main">
                <div class="card-body">
                    <!-- info sync -->
                    <div class="row">
                        <div class="col-12">
                            <h5>{{item.group}}</h5>
                        </div>
                    </div>
                    <div class="card-details">
                        <div class="row mt-3 mb-3">
                            <div class="col-6 col-sm-3 text-center" *ngFor="let itemB of item.reports">
                                <button type="button" class="btn btn-lg mb-1" [ngClass]="item.btn_state"
                                    [routerLink]="itemB.url">
                                    <h1><i [ngClass]="itemB.icon"></i></h1>
                                </button>
                                <h6>{{itemB.name_a}} <br>{{itemB.name_b}}</h6>
                            </div>
                        </div>
                    </div>
                    <!-- end info sync -->
                </div>
            </div>
        </div>
    </div>
</div>