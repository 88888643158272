import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceObject } from '../models/service-object';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';
import { WebAPIService } from '../services/web-api.service';

@Component({
  selector: 'app-users-form',
  templateUrl: './users-form.component.html',
  styleUrls: ['./users-form.component.scss']
})
export class UsersFormComponent implements OnInit {

  @Input() user: any;
  @Input() title!: string;
  form!: FormGroup;
  groups: any = [];
  roles: any = [];
  gender: any = [];
  types: any = [];
  role!: number;
  errors: any = [];

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private webAPI: WebAPIService, private notifyService: NotificationService, public authS: AuthService) { }

  ngOnInit(): void {
    this.authS.GetUser();

    var servObj = new ServiceObject("groups");
    this.webAPI.GetAction(servObj)
      .then(x => {
        servObj = <ServiceObject>x;
        this.groups = x.data.groups;
      }).catch(x => { throw x.message; });

    var servObj = new ServiceObject("roles");
    this.webAPI.GetAction(servObj)
      .then(x => {
        servObj = <ServiceObject>x;
        this.roles = x.data.roles;
      }).catch(x => { throw x.message; });

    var servObj = new ServiceObject("gender");
    this.webAPI.GetAction(servObj)
      .then(x => {
        servObj = <ServiceObject>x;
        this.gender = x.data.gender;
      }).catch(x => { throw x.message; });

    var servObj = new ServiceObject("types");
    this.webAPI.GetAction(servObj)
      .then(x => {
        servObj = <ServiceObject>x;
        this.types = x.data.type;
      }).catch(x => { throw x.message; });

    this.form = this.formBuilder.group({
      firstname: [this.user ? this.user.firstname : "", Validators.compose([Validators.required])],
      lastname: [this.user ? this.user.lastname : "", Validators.compose([Validators.required])],
      identification: [this.user ? this.user.identification : "", Validators.compose([Validators.required])],
      gender: [this.user ? this.user.gender_id : "", Validators.compose([Validators.required])],
      identification_type: [this.user ? this.user.identification_type_id : "", Validators.compose([Validators.required])],
      phone: [this.user ? this.user.phone : "", Validators.compose([Validators.required])],
      email: [this.user ? this.user.email : "", Validators.compose([Validators.required])],
      pass: [""],
      pass_confirm: [""],
      role: [{ value: this.user ? this.user.roles[0].id : "", disabled: this.authS.user.roles[0].pivot.role_id == 3 }, Validators.compose([Validators.required])],
      group: [{ value: this.user ? this.user.user_role[0].groups[0].id : "", disabled: this.authS.user.roles[0].pivot.role_id == 3 }, Validators.compose([Validators.required])]
    });
  }

  Save() {
    if (!this.form.invalid) {
      var user = {
        "género": this.form.controls.gender.value,
        "tipo_de_identificación": this.form.controls.identification_type.value,
        "usuario": this.form.controls.email.value,
        "correo": this.form.controls.email.value,
        "primer_nombre": this.form.controls.firstname.value,
        "primer_apellido": this.form.controls.lastname.value,
        "número_de_identificación": this.form.controls.identification.value,
        "estado": 1,
        "contraseña": this.form.controls.pass.value,
        "confirmar_contraseña": this.form.controls.pass_confirm.value,
        "role_id": this.form.controls.role.value,
        "group_id": this.form.controls.group.value,
        "celular": this.form.controls.phone.value,
      }
      var servObj = new ServiceObject("user");
      servObj.data = user;
      if (this.user) {
        servObj.id = this.user.id;
        this.webAPI.PutAction(servObj)
          .then(x => {
            servObj = <ServiceObject>x;
            this.notifyService.showSuccess(servObj.message, "");
            this.activeModal.close();
          }).catch(x => { throw x.message; });
      } else {
        this.webAPI.PostAction(servObj)
          .then(x => {
            servObj = <ServiceObject>x;
            this.notifyService.showSuccess(servObj.message, "");
            this.activeModal.close();
          }).catch(x => {
            this.ShowError(x.error.data.errors);
            throw x.message;
          });
      }
    } else
      this.notifyService.showWarning("Todos los campos del formulario son obligatorios", "");
  }

  ShowError(errors: any) {
    this.errors = [];
    var keys = Object.keys(errors);
    keys.forEach(element => {
      for (let index = 0; index < errors[element].length; index++) {
        const error = errors[element][index];
        this.errors.push(error);
      }
    });
  }
}
