import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { QuestionsComponent } from './questions/questions.component';
import { AvgReportComponent } from './reports/avg-report/avg-report.component';
import { DailyReportComponent } from './reports/daily-report/daily-report.component';
import { GroupReportComponent } from './reports/group-report/group-report.component';
import { GroupTicketReportComponent } from './reports/group-ticket-report/group-ticket-report.component';
import { HoraryDayReportComponent } from './reports/horary-day-report/horary-day-report.component';
import { ReportsComponent } from './reports/reports.component';
import { TicketsReportComponent } from './reports/tickets-report/tickets-report.component';
import { UserReportComponent } from './reports/user-report/user-report.component';
import { SyncComponent } from './sync/sync.component';
import { TicketsComponent } from './tickets/tickets.component';
import { UsersComponent } from './users/users.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'login/:id_ticket', component: LoginComponent },
  { path: 'support', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  { path: 'tickets', component: TicketsComponent },
  { path: 'tickets/:status_id', component: TicketsComponent },
  { path: 'reports', component: ReportsComponent },
  { path: 'users', component: UsersComponent },
  { path: 'questions', component: QuestionsComponent },
  { path: 'sync', component: SyncComponent },
  { path: 'user-reports', component: UserReportComponent },
  { path: 'group-reports', component: GroupReportComponent },
  { path: 'avg-reports', component: AvgReportComponent },
  { path: 'daily-reports', component: DailyReportComponent },
  { path: 'horary-day-reports', component: HoraryDayReportComponent },
  { path: 'group-ticket-reports', component: GroupTicketReportComponent },
  { path: 'all-reports', component: TicketsReportComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
