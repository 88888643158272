import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartType, RadialChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { ServiceObject } from 'src/app/models/service-object';
import { WebAPIService } from 'src/app/services/web-api.service';

@Component({
  selector: 'app-group-report',
  templateUrl: './group-report.component.html',
  styleUrls: ['./group-report.component.scss']
})
export class GroupReportComponent implements OnInit {

  groups: any = [];
  isLoading: boolean = false;

  public radarChartOptions: RadialChartOptions = {
    responsive: true,
  };
  public radarChartLabels: Label[] = [];

  public radarChartData: ChartDataSets[] = [];

  public radarChartType: ChartType = 'radar';

  constructor(private webAPI: WebAPIService) { }

  ngOnInit(): void {
    var servObj = new ServiceObject("groups");
    this.webAPI.GetAction(servObj)
      .then(x => {
        servObj = <ServiceObject>x;
        this.groups = x.data.groups;
      }).catch(x => { throw x.message; });
  }

  onChange(e: any) {
    if (e) {
      this.isLoading = false;
      var array: any[] = [];
      this.radarChartLabels = [];
      var servObj = new ServiceObject("report/user");
      servObj.id = e.target.value;
      this.webAPI.GetAction(servObj)
        .then(x => {
          servObj = <ServiceObject>x;
          x.data.report.forEach((element: any) => {
            this.radarChartLabels.push(element.name);
            array.push(element.total);
          });
          this.radarChartData = [{ data: array, label: 'Total' }];
          this.isLoading = true;
        }).catch(x => { throw x.message; });
    }
  }
}
