import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sync',
  templateUrl: './sync.component.html',
  styleUrls: ['./sync.component.scss']
})
export class SyncComponent implements OnInit {

  sync = [
    {
      name: "Pedagogía",
      email: "pedagogia@ejrlb.edu.co",
      status: "Cerrado",
    },
    {
      name: "Plataforma",
      email: "plataforma@edured.edu.co",
      status: "Abierto",
    },
    {
      name: "Desarrollo",
      email: "developer@edured.edu.co",
      status: "En Curso",
    },
    {
      name: "Administrativo Edured",
      email: "contabilidad@edured.edu.co",
      status: "Abierto",
    },
    {
      name: "Administrativo EJRLB",
      email: "contabilidad@ejrlb.edu.co",
      status: "Cerrado",
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
