<div class="container-fluid">
    <div class="row mb-4 mt-4">
        <div *ngFor="let item of report" class="card-col col-6 col-sm-4 col-md-2">
            <div class="card card-number mb-1 mt-1" [ngClass]="'border-' + (item.css_class ? item.css_class : 'danger')" (click)="navigate(item)">
                <div class="card-body">
                    <small class="text-muted">{{item.name ? item.name : 'Sin revisar'}}</small>
                    <h3 style="text-decoration: underline;">{{item.tickets}}</h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="barChartData">
        <div class="col-12 col-sm-12 col-md-12">
            <div class="card chart-wrapper">
                <div class="card-body">
                    <small class="text-muted">{{day}}, {{hour}}</small>
                    <h4>Estadisticas de hoy</h4>
                    <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                        [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType">
                    </canvas>
                </div>
            </div>
        </div>
        <!-- <div class="col-12 col-sm-12 col-md-2">
            <div class="card mb-1 mt-1">
                <div class="card-body">
                    <small class="text-muted">Resueltos</small>
                    <h3>36</h3>
                </div>
            </div>
            <div class="card mb-1 mt-1">
                <div class="card-body">
                    <small class="text-muted">Por Resolver</small>
                    <h3>84</h3>
                </div>
            </div>
            <div class="card mb-1 mt-1">
                <div class="card-body">
                    <small class="text-muted">Promedio tiempo de respuesta</small>
                    <h3>12m</h3>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-2">
            <div class="card mb-1 mt-1">
                <div class="card-body">
                    <small class="text-muted">Registrados</small>
                    <h3>124</h3>
                </div>
            </div>
            <div class="card mb-1 mt-1">
                <div class="card-body">
                    <small class="text-muted">En Curso</small>
                    <h3>4</h3>
                </div>
            </div>
            <div class="card mb-1 mt-1">
                <div class="card-body">
                    <small class="text-muted">Promedio tiempo de nuevos registros</small>
                    <h3>45m</h3>
                </div>
            </div>
        </div> -->
    </div>
</div>