import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { transpileModule } from 'typescript';
import { ServiceObject } from '../models/service-object';
import { AuthService } from '../services/auth.service';
import { NotificationService } from '../services/notification.service';
import { WebAPIService } from '../services/web-api.service';
import { AuthUser } from '../users/interfaces/user';

@Component({
  selector: 'app-tickets-form',
  templateUrl: './tickets-form.component.html',
  styleUrls: ['./tickets-form.component.scss']
})
export class TicketsFormComponent implements OnInit {

  @Input() item: any;
  @Input() currentDate: any;
  @Input() free: any = false;
  @Input() onlyRead?: boolean;
  assing_add!: boolean;
  status_add!: boolean;
  refresh!: boolean;
  users: any = [];
  status: any = [];
  groups: any = [];
  selectedOption: any;
  selectedStatus: any;
  selectedGroup: any;
  permissionTicket: any;
  emailValid: boolean = false;
  email: string = "";
  role_group: any;
  public form!: FormGroup;
  storage!: string;
  user?: AuthUser;

  @ViewChild("fileUpload", { static: false }) fileUpload!: ElementRef; files = [];

  constructor(
    public activeModal: NgbActiveModal,
    private webAPI: WebAPIService,
    private toast: NotificationService,
    public authS: AuthService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.user = this.authS.GetUser();
    this.storage = environment.storage;
    this.role_group = this.authS.GetUser().user_role[0].user_role_group[0].group_id
    this.form = this.formBuilder.group({
      status_id: ["0", Validators.compose([Validators.required])],
      description: ["", Validators.compose([Validators.required])],
      phase: ["", Validators.compose([Validators.required])],
      group_id: [""],
      path_media: [""]
    });
    if (!this.free) {
      this.GetUsers();
      var servObj = new ServiceObject("status");
      this.webAPI.GetAction(servObj)
        .then(x => {
          servObj = <ServiceObject>x;
          this.status = x.data.status;
        }).catch(x => { throw x.message; });

      var servObj = new ServiceObject("groups");
      this.webAPI.GetAction(servObj)
        .then(x => {
          servObj = <ServiceObject>x;
          this.groups = x.data.groups;
        }).catch(x => { throw x.message; });
    }
    this.updateTicket();
  }

  toogleAssign() {
    this.assing_add = !this.assing_add;
  }

  toogleStatus() {
    this.status_add = !this.status_add;
  }

  assignUser() {
    if (this.selectedOption > 0) {
      var servObj = new ServiceObject("userRole");
      servObj.data = {
        "user_role_group_id": this.selectedOption,
        "ticket_id": this.item.id
      };
      this.webAPI.PostAction(servObj)
        .then(x => {
          servObj = <ServiceObject>x;
          this.assing_add = false;
          this.toast.showSuccess(servObj.message, "");
          this.updateTicket(true);
        }).catch(x => { throw x.message; });
    } else {
      this.toast.showWarning("Seleccione un usuario", "");
    }
  }

  deleteAssignUser(urg_id: any) {
    if (this.authS.user.roles[0].pivot.role_id != 3) {
      var servObj = new ServiceObject("userRole");
      servObj.id = urg_id;
      this.webAPI.DeleteAction(servObj)
        .then(x => {
          servObj = <ServiceObject>x;
          this.toast.showSuccess(servObj.message, "");
          this.updateTicket(true);
        }).catch(x => { throw x.message; });
    }
  }

  assignStatus() {
    if (this.form.invalid) {
      this.toast.showWarning("Ingrese estado, descripción y convenio", "");
    } else {
      const fileUpload = this.fileUpload.nativeElement;
      var formData = new FormData();
      var data = this.form.controls;
      formData.append('ticket_id', this.item.id);
      formData.append('user_role_group_ticket_id', this.permissionTicket.pivot.id);
      formData.append('description', data.description.value);
      formData.append('phase', data.phase.value);
      formData.append('status_id', data.status_id.value);
      formData.append('group_id', data.group_id.value);
      if (fileUpload.files.length) {
        const file = fileUpload.files[0];
        formData.append('path_media', file);
      }
      var servObj = new ServiceObject("ticketUrgStatus");
      servObj.data = formData;
      this.webAPI.PostAction(servObj)
        .then(x => {
          servObj = <ServiceObject>x;
          this.toast.showSuccess(servObj.message, "");
          this.toogleStatus();
          this.updateTicket(true);
        }).catch(x => { throw x.message; });
    }
  }

  closeModal() {
    if (!this.free) {
      this.activeModal.close(this.refresh);
    }
  }

  updateTicket(opt?: boolean) {
    if (opt) {
      this.refresh = true;
      var paramsMain = new HttpParams();

      paramsMain = paramsMain.append("id", this.item.id);

      var servObj = new ServiceObject("ticket");
      this.webAPI.GetAction(servObj, paramsMain)
        .then(x => {
          servObj = <ServiceObject>x;
          this.item = x.data.tickets[0];
          this.GetUsers();
        }).catch(x => { throw x.message; });
    }

    this.item.user_role_groups.forEach((element: any) => {
      if (this.authS.user.roles[0].pivot.id == element.user_role_id)
        this.permissionTicket = element;
    });
  }

  GetUsers() {
    var paramsMain = new HttpParams();
    var servObj = new ServiceObject("userRole");
    this.webAPI.GetAction(servObj, paramsMain)
      .then(x => {
        servObj = <ServiceObject>x;
        this.users = x.data.userRole;
      }).catch(x => { throw x.message; });
  }

  validateEmail() {
    if (this.email == this.item.email) {
      this.emailValid = true;
      this.toast.showSuccess("Verificación completa", "");
    } else if (this.email == "") {
      this.toast.showError("El campo esta vacio", "");
    } else {
      this.toast.showError("El correo no coincide", "");
    }
  }
}
