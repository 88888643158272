<div class="container-fluid">
    <div class="row mt-4 mb-4">
        <div class="card-col col-12">
            <div class="card mb-2 mt-2 d-flex card-main">
                <div class="card-body">
                    <div class="row">
                        <div class="col-2 col-sm-2 col-md-1">
                            <button type="button" class="btn btn-lg mb-1" class="btn btn-lg mb-1 btn-outline-info">
                                <h1><i class="fas fa-users"></i></h1>
                            </button>
                        </div>
                        <div class="col-10 col-sm-10 col-md-11 justify-content-center align-self-center">
                            <h3>Desempeño por grupo</h3>
                        </div>
                        <div class="mt-4 mb-4 col-12">
                            <label class="form-label">Seleccione un grupo</label>
                            <select class="form-select" (change)="onChange($event)">
                                <option value="0">...</option>
                                <option *ngFor="let item of groups" [value]="item.id">{{item.name}}</option>
                            </select> 
                        </div>
                        <div class="mt-4 mb-4 col-12">
                            <div class="chart-wrapper" *ngIf="isLoading">
                                <canvas baseChart [datasets]="radarChartData" [options]="radarChartOptions"
                                    [labels]="radarChartLabels" [chartType]="radarChartType">
                                </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>