import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { ServiceObject } from 'src/app/models/service-object';
import { WebAPIService } from 'src/app/services/web-api.service';

@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.scss']
})
export class DailyReportComponent implements OnInit {

  isLoading: boolean = false;
  reportHorary: any = [];

  barChartOptions: ChartOptions = {
    responsive: true,
  };
  barChartLabels!: Label[];
  barChartType: ChartType = 'line';
  barChartLegend = true;
  barChartPlugins = [];
  barChartData!: ChartDataSets[];

  constructor(private webAPI: WebAPIService) { }

  ngOnInit(): void {
  }

  onChange(e: any) {
    if (e) {
      var date = e.target.value.split("-");
      this.isLoading = false;
      var servObj = new ServiceObject("report/daily/" + date[1] + "/" + date[0]);
      this.webAPI.GetAction(servObj)
        .then(x => {
          servObj = <ServiceObject>x;
          var values: any = [];
          this.reportHorary = x.data.report;
          this.barChartLabels = Object.keys(this.reportHorary[0]);
          Object.keys(this.reportHorary[0]).forEach((element: any) => {
            values.push(this.reportHorary[0][element]);
          });
          this.barChartData = [
            { data: values, label: 'Casos por mes' }
          ];
          this.isLoading = true;
        }).catch(x => { throw x.message; });
    }
  }
}
