import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceObject } from '../models/service-object';
import { AuthService } from '../services/auth.service';
import { WebAPIService } from '../services/web-api.service';
import { UsersFormComponent } from '../users-form/users-form.component';
import { GetUser } from './interfaces/users';
import { AuthUser } from './interfaces/user';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  users: GetUser[] = [];
  groups: any = [];
  roles: any = [];
  searchForm!: FormGroup;
  user?: AuthUser;

  constructor(
    private webAPI: WebAPIService,
    private formBuilder: FormBuilder,
    public authS: AuthService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.user = this.authS.GetUser();

    this.searchForm = this.formBuilder.group({
      group: ['0'],
      role: ['0'],
      names: [''],
      lastnames: [''],
      email: [''],
    });

    this.GetUsers();

    var servObj = new ServiceObject('groups');
    this.webAPI
      .GetAction(servObj)
      .then((x) => {
        servObj = <ServiceObject>x;
        this.groups = x.data.groups;
      })
      .catch((x) => {
        throw x.message;
      });

    var servObj = new ServiceObject('roles');
    this.webAPI
      .GetAction(servObj)
      .then((x) => {
        servObj = <ServiceObject>x;
        this.roles = x.data.roles;
      })
      .catch((x) => {
        throw x.message;
      });
  }

  GetUsers() {
    var paramsMain = new HttpParams();
    this.users = [];

    if (this.searchForm.controls.group.value > 0)
      paramsMain = paramsMain.append(
        'group_id',
        this.searchForm.controls.group.value
      );

    if (this.searchForm.controls.role.value > 0)
      paramsMain = paramsMain.append(
        'role_id',
        this.searchForm.controls.role.value
      );

    if (this.searchForm.controls.names.value.length > 0)
      paramsMain = paramsMain.append(
        'firstname',
        this.searchForm.controls.names.value
      );

    if (this.searchForm.controls.lastnames.value.length > 0)
      paramsMain = paramsMain.append(
        'lastname',
        this.searchForm.controls.lastnames.value
      );

    if (this.searchForm.controls.email.value.length > 0)
      paramsMain = paramsMain.append(
        'email',
        this.searchForm.controls.email.value
      );

    if (this.authS.user.roles[0].pivot.role_id == 3)
      paramsMain = paramsMain.append('id', this.authS.user.id);

    var servObj = new ServiceObject('user');
    this.webAPI
      .GetAction(servObj, paramsMain)
      .then((x) => {
        servObj = <ServiceObject>x;
        this.users = x.data.users;

        if (this.user && this.user.roles[0].id === 4) {
          this.users = this.users.filter(
            (x) => x.id == Number(this.authS.user.id)
          );
        }
      })
      .catch((x) => {
        throw x.message;
      });
  }

  open(item: any) {
    const modalRef = this.modalService.open(UsersFormComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.user = item;
    modalRef.componentInstance.title = 'Información del usuario';
  }

  getGroupName(user: any): string {
    return user?.user_role?.[0]?.groups?.[0]?.name || 'No user_role group name';
  }
}
