import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "diffDays"
})
export class DiffDaysPipe implements PipeTransform {
    transform(dateInit: string, dateFinal: string): any {
        var fechaInicio = new Date(dateInit).getTime();
        var fechaFin = new Date(dateFinal).getTime();
        var diff = fechaFin - fechaInicio;
        diff = Math.round(diff / (1000 * 60 * 60 * 24));
        return "Creado hace " + diff + (diff == 1 ? " día" : " días");
    }
}