import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  menu = [
    {
      icon: "fa-home",
      name: "Inicio",
      path: "home",
      state: false
    },
    {
      icon: "fa-ticket-alt",
      name: "Tickets",
      path: "tickets",
      state: false
    },
    {
      icon: "fa-chart-bar",
      name: "Reportes",
      path: "reports",
      state: false
    },
    {
      icon: "fa-address-card",
      name: "Gestión Usuarios",
      path: "users",
      state: false
    },
    {
      icon: "fa-question-circle",
      name: "Preguntas Frecuentes",
      path: "questions",
      state: false
    },
    {
      icon: "fa-sync",
      name: "Escalar Casos",
      path: "sync",
      state: false
    },
  ];
  itemSelected: any;

  constructor(private router: Router, private location: Location, public authS: AuthService) { }

  userInfo: any = {
    names: "",
    role: ""
  };

  ngOnInit() {
    this.authS.GetUser();
    this.userInfo.names = (this.authS.user ? this.authS.user.firstname : '') + ' ' + (this.authS.user ? this.authS.user.lastname : '');
    this.userInfo.role = this.authS.user ? this.authS.user.roles[0].name : '';
    if (this.location.path().length > 0 && !this.location.path().includes("login")  && !this.location.path().includes("support")) {
      this.itemSelected = this.menu.find(item => this.location.path().includes(item.path));
      this.navigate(this.itemSelected);
    } else {
      this.itemSelected = this.menu[0];
    }
    $(document).ready(function () {
      $('#dismiss, .overlay, .forgot-password').on('click', function () {
        $('#sidebar').removeClass('active');
        $('.overlay').removeClass('active');
        $('#footer').removeClass('footer-hide');
      });
      $('#sidebarCollapse').on('click', function () {
        $('#sidebar').addClass('active');
        $('.overlay').addClass('active');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
        $('#footer').addClass('footer-hide');
      });
    });
  }

  navigate(option: any) {
    this.menu.forEach(item => { item.state = false });
    this.menu.forEach(item => {
      if (option.path == item.path) {
        this.itemSelected = item;
        item.state = true;
        this.router.navigateByUrl(item.path);
      }
    });
    $('#sidebar').removeClass('active');
    $('.overlay').removeClass('active');
    $('#footer').removeClass('footer-hide');
  }

  logout() {
    this.authS.logout();
    this.router.navigateByUrl("login");
  }
}
