<app-my-result [number]="users.length"></app-my-result>
<div class="container-fluid">
  <div class="row mt-4 mb-4">
    <div class="card-col col-12 col-sm-12 col-md-12 col-lg-9">
      <div class="card mb-2 mt-2 d-flex">
        <div class="card-body">
          <!-- info users -->
          <div class="table-responsive">
            <table class="table align-middle">
              <thead>
                <tr>
                  <th scope="col">Usuario</th>
                  <th scope="col">Grupo</th>
                  <th scope="col">Correo</th>
                  <th scope="col">Rol</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let user of users">
                  <td>
                    <div class="row">
                      <!-- <div class="col-1 justify-content-center align-self-center text-center">
                                                <input class="form-check-input" type="checkbox">
                                            </div> -->
                      <div
                        class="col-2 justify-content-center align-self-center text-center"
                      >
                        <button
                          type="button"
                          class="btn"
                          [ngClass]="'btn-outline-success'"
                          (click)="open(user)"
                        >
                          {{ user && user.firstname | shortName }}
                        </button>
                      </div>
                      <div
                        class="col-10 justify-content-center align-self-center"
                      >
                        <h6>
                          {{ user && user.firstname }}
                          {{ user && user.lastname }}
                        </h6>
                      </div>
                    </div>
                  </td>
                  <td>{{ getGroupName(user) }}</td>
                  <td>{{ user && user.email }}</td>
                  <td>{{ user && user.roles[0].name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- end info users -->
        </div>
      </div>
    </div>
    <div
      class="card-col col-12 col-sm-12 col-md-12 col-lg-3"
      *ngIf="authS.user.roles[0].pivot.role_id != 3"
    >
      <div class="card m-3">
        <div class="card-body">
          <h5><i class="fas fa-search"></i> Filtros</h5>
          <form [formGroup]="searchForm" (ngSubmit)="GetUsers()">
            <div class="mb-3">
              <label class="form-label">Grupo</label>
              <select class="form-select" formControlName="group">
                <option value="0">Todos</option>
                <option *ngFor="let item of groups" [value]="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label class="form-label">Rol</label>
              <select class="form-select" formControlName="role">
                <option value="0">Todos</option>
                <option *ngFor="let item of roles" [value]="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label class="form-label">Nombres</label>
              <input type="text" class="form-control" formControlName="names" />
            </div>
            <div class="mb-3">
              <label class="form-label">Apellidos</label>
              <input
                type="text"
                class="form-control"
                formControlName="lastnames"
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Email</label>
              <input type="text" class="form-control" formControlName="email" />
            </div>
            <div class="d-grid gap-2">
              <button class="btn btn-success" type="submit">Filtrar</button>
            </div>
          </form>
          <div class="d-grid gap-2 mt-2">
            <button
              class="btn btn-warning"
              (click)="open(null)"
              *ngIf="user && user.roles[0].id !== 4"
            >
              Crear Usuario
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
