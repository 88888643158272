<div class="wrapper">
  <!-- Sidebar  -->
  <nav id="sidebar">
    <div id="dismiss">
      <i class="fas fa-times"></i>
    </div>
    <div class="sidebar-header">
      <div class="row">
        <div class="col-2">
          <img
            src="assets/user_circle.png"
            class="rounded-circle"
            alt="User"
            width="50px"
          />
        </div>
        <div class="col-10">
          <h5>{{ userInfo.names }}</h5>
          <small>{{ userInfo.role }}</small
          ><br />
          <a (click)="logout()" class="forgot-password">Cerrar sesión</a>
        </div>
      </div>
    </div>
    <div class="sidebar-body">
      <!-- <div class="alert alert-primary" role="alert">
        Tienes (10) nuevas notificaciones
      </div> -->
      <div class="row text-center">
        <div class="col-4" *ngFor="let item of menu">
          <div
            class="sidebar-item card"
            [ngClass]="item.state ? 'item-active-menu' : ''"
            (click)="navigate(item)"
          >
            <div class="card-body">
              <i class="fas {{ item.icon }} fa-3x"></i>
              <small>{{ item.name }}</small>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="alert alert-danger" role="alert">
        Tienes (9) nuevas asignaciones
      </div> -->
    </div>
  </nav>
  <!-- End Sidebar  -->
  <!-- Page Content  -->
  <div id="content">
    <app-nav [title]="itemSelected"></app-nav>
    <div class="container-fluid" style="margin-bottom: 100px">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
  <!-- End Page Content  -->
</div>
<div class="overlay"></div>
<app-my-loader></app-my-loader>
