<div class="container-fluid">
    <div class="row mt-4 mb-4">
        <div class="card-col col-12">
            <div class="card mb-2 mt-2 d-flex card-main">
                <div class="card-body">
                    <!-- info sync -->
                    <div class="row">
                        <div class="col-12">
                            <h5>Automatización de casos</h5>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 mt-2 mb-2">
                            <div class="alert alert-primary" role="alert">
                                Sin registros
                            </div>
                        </div>
                        <!-- <div class="col-12 col-sm-12 col-md-6 mt-2 mb-2" *ngFor="let item of sync">
                            <div class="card">
                                <div class="card-body">
                                    <h6 class="card-header mb-2"><i class="fas fa-sync"></i> {{item.name}}</h6>
                                    <div class="card-details">
                                        <h6>
                                            <i class="fas fa-envelope"></i>
                                            {{item.email}} -
                                            <small class="text-muted">Envío automatico de correo</small>
                                        </h6>
                                        <h6>
                                            <i class="fas fa-thermometer-three-quarters"></i>
                                            {{item.status}} -
                                            <small class="text-muted">Estado por defecto</small>
                                        </h6>
                                        <h6>
                                            <i class="fas fa-users"></i>
                                            {{item.name}} -
                                            <small class="text-muted">Grupo por defecto</small>
                                        </h6>
                                    </div>
                                    <div class="text-end">
                                        <button class="btn btn-success">Editar</button>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <!-- end info sync -->
                </div>
            </div>
        </div>
    </div>
</div>