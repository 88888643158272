import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home.component';
import { TicketsComponent } from './tickets/tickets.component';
import { ReportsComponent } from './reports/reports.component';
import { UsersComponent } from './users/users.component';
import { QuestionsComponent } from './questions/questions.component';
import { SyncComponent } from './sync/sync.component';
import { ChartsModule } from 'ng2-charts';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShortNamePipe } from './pipes/shortName.pipe';
import { DiffDaysPipe } from './pipes/diffDays.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TicketsFormComponent } from './tickets-form/tickets-form.component';
import { UsersFormComponent } from './users-form/users-form.component';
import { MyLoaderComponent } from './my-loader/my-loader.component';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './interceptor/loader-interceptor.service';
import { MyResultComponent } from './my-result/my-result.component';
import { TicketFormComponent } from './ticket-form/ticket-form.component';
import { UserReportComponent } from './reports/user-report/user-report.component';
import { GroupReportComponent } from './reports/group-report/group-report.component';
import { AvgReportComponent } from './reports/avg-report/avg-report.component';
import { DailyReportComponent } from './reports/daily-report/daily-report.component';
import { HoraryDayReportComponent } from './reports/horary-day-report/horary-day-report.component';
import { GroupTicketReportComponent } from './reports/group-ticket-report/group-ticket-report.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { TicketsReportComponent } from './reports/tickets-report/tickets-report.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavComponent,
    HomeComponent,
    TicketsComponent,
    ReportsComponent,
    UsersComponent,
    QuestionsComponent,
    SyncComponent,
    FooterComponent,
    ShortNamePipe,
    DiffDaysPipe,
    TicketsFormComponent,
    UsersFormComponent,
    MyLoaderComponent,
    MyResultComponent,
    TicketFormComponent,
    UserReportComponent,
    GroupReportComponent,
    AvgReportComponent,
    DailyReportComponent,
    HoraryDayReportComponent,
    GroupTicketReportComponent,
    TicketsReportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ChartsModule,
    NgxPaginationModule,
    HttpClientModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
})
export class AppModule { }
