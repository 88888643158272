import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from '../services/notification.service';
import { Auth } from '../models/auth';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private router: Router, private notifyService: NotificationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: Auth = this.authService.getToken();
        if (token && token.access_token != null) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token.access_token) });
        }

        /*if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }*/

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                // if (event instanceof HttpResponse) {console.log('event--->>>', event);}
                return event;
            }), catchError((error: HttpErrorResponse) => {
                this.notifyService.showError("Ocurrió un error", error.error.msg ? error.error.msg : error.error.message)
                new Promise<void>((res) => {
                    if (error.status === 401 || error.status === 403) {
                        setTimeout(() => {
                            this.router.navigateByUrl('');
                            res();
                        }, 4000);
                    }
                });
                return throwError(error);
            })
        );
    }
}
