// my-loader.component.ts
import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy, AfterContentChecked } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-my-loader',
  templateUrl: './my-loader.component.html',
  styleUrls: ['./my-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyLoaderComponent implements OnInit, OnDestroy, AfterContentChecked {

  loading!: boolean;
  private subscription!: Subscription;

  constructor(private loaderService: LoaderService, private changeDedectionRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.subscription = this.loaderService.isLoading.subscribe((v: boolean) => {
      this.loading = v;
    });
  }

  ngAfterContentChecked(): void {
    this.changeDedectionRef.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
