<div class="container-fluid">
    <div class="row mt-4 mb-4">
        <div class="card-col col-12">
            <div class="card mb-2 mt-2 d-flex card-main">
                <div class="card-body">
                    <div class="row">
                        <div class="col-2 col-sm-2 col-md-1">
                            <button type="button" class="btn btn-lg mb-1" class="btn btn-lg mb-1 btn-outline-info">
                                <h1><i class="fas fa-stopwatch"></i></h1>
                            </button>
                        </div>
                        <div class="col-10 col-sm-10 col-md-11 justify-content-center align-self-center">
                            <h3>Tiempo promedio de respuesta</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="isLoading">
        <div class="col">
            <div class="card mb-1 mt-1 border-info">
                <div class="card-body">
                    <small class="text-muted">En Segundos</small>
                    <h1>{{seconds | number:'2.1'}}</h1>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card mb-1 mt-1 border-info">
                <div class="card-body">
                    <small class="text-muted">En Minutos</small>
                    <h1>{{minutes | number:'2.1'}}</h1>
                </div>
            </div>
        </div>
        <div class="col-12 col-sm-12 col-md-4">
            <div class="card mb-1 mt-1 border-info">
                <div class="card-body">
                    <small class="text-muted">En Días</small>
                    <h1>{{days | number:'2.1'}}</h1>
                </div>
            </div>
        </div>
    </div>
</div>