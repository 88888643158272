import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { ServiceObject } from '../models/service-object';
import { WebAPIService } from '../services/web-api.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  report: any = [];
  reportHorary: any = [];
  day!: string;
  hour!: string;

  barChartOptions: ChartOptions = {
    responsive: true,
  };
  barChartLabels!: Label[];
  barChartType: ChartType = 'line';
  barChartLegend = true;
  barChartPlugins = [];
  barChartData!: ChartDataSets[];

  constructor(private webAPI: WebAPIService, private route: Router) { }

  ngOnInit(): void {
    var today = new Date();
    this.day = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
    this.hour = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();

    var servObj = new ServiceObject("report/abstract");
    this.webAPI.GetAction(servObj)
      .then(x => {
        servObj = <ServiceObject>x;
        this.report = x.data.report;
      }).catch(x => { throw x.message; });

    var servObj = new ServiceObject("report/horary");
    this.webAPI.GetAction(servObj)
      .then(x => {
        servObj = <ServiceObject>x;
        var values: any = [];
        this.reportHorary = x.data.report;
        this.barChartLabels = Object.keys(this.reportHorary[0]);
        Object.keys(this.reportHorary[0]).forEach((element: any) => {
          values.push(this.reportHorary[0][element]);
        });
        this.barChartData = [
          { data: values, label: 'Concurrencia' }
        ];
      }).catch(x => { throw x.message; });
  }

  navigate(option: any) {
    this.route.navigateByUrl( option.id ? 'tickets/' + option.id : 'tickets/' + 100);
  }

}
