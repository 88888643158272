import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AuthUser } from '../users/interfaces/user';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  user?: AuthUser;

  role_group: any;
  filteredReports: any;

  reports = [
    {
      group: 'Productividad (4)',
      btn_state: 'btn-outline-info',
      reports: [
        {
          name_a: 'Desempeño',
          name_b: 'por usuario',
          icon: 'fas fa-user',
          url: '../user-reports',
        },
        {
          name_a: 'Desempeño',
          name_b: 'por grupo',
          icon: 'fas fa-users',
          url: '../group-reports',
        },
        {
          name_a: 'Tiempo',
          name_b: 'de respuesta',
          icon: 'fas fa-stopwatch',
          url: '../avg-reports',
        },
        // {
        //   name_a: "Distribución",
        //   name_b: "de desempeño",
        //   icon: "fas fa-balance-scale",
        //   url: "../user-reportss"
        // },
      ],
    },
    {
      group: 'Concurrencia (4)',
      btn_state: 'btn-outline-success',
      reports: [
        {
          name_a: 'Casos',
          name_b: 'por día',
          icon: 'fas fa-calendar-check',
          url: '../horary-day-reports',
        },
        {
          name_a: 'Casos',
          name_b: 'por mes',
          icon: 'fas fa-calendar-alt',
          url: '../daily-reports',
        },
        {
          name_a: 'Casos',
          name_b: 'de grupo',
          icon: 'fas fa-users',
          url: '../group-ticket-reports',
        },
        {
          name_a: 'Descargar',
          name_b: 'Tickets',
          icon: 'fas fa-chart-bar',
          url: '../all-reports',
        },
      ],
    },
  ];

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.user = this.authService.GetUser();
    console.log('this.user', this.user);


    if (this.user && this.user.roles[0].id === 4) {
      this.reports.shift();
      this.reports[0].group = 'Concurrencia (1)';
      this.reports[0].reports = this.reports[0].reports.slice(3);

      console.log('this.reports', this.reports);

    }

    this.role_group =
      this.authService.GetUser().user_role[0].user_role_group[0].group_id;
    this.filterReports();
  }

  filterReports() {
    if (this.role_group === 4) {
      this.filteredReports = this.reports.map((group) => {
        if (group.group === 'Productividad (4)') {
          return {
            ...group,
            reports: group.reports.slice(1),
          };
        } else {
          return group;
        }
      });
    } else {
      this.filteredReports = this.reports;
    }
  }
}
