import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceObject } from '../models/service-object';
import { AuthService } from '../services/auth.service';
import { WebAPIService } from '../services/web-api.service';
import { TicketFormComponent } from '../ticket-form/ticket-form.component';
import { TicketsFormComponent } from '../tickets-form/tickets-form.component';
import { Ticket, TicketResponse } from './interfaces/ticket';
import { AuthUser } from '../users/interfaces/user';

const INIT_CURRENT_PAGE = 1;
const INIT_TOTAL_ITEMS = 0;
const INIT_ITEMS_PER_PAGE = 10;

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss'],
})
export class TicketsComponent implements OnInit {
  status: any = [];
  users: any = [];
  priority: any = [];
  tickets: Ticket[] = [];
  currentDate!: string;
  status_id!: string;
  searchForm!: FormGroup;
  p: number = 1;

  user?: AuthUser;

  currentPage: number = INIT_CURRENT_PAGE;
  totalItems: number = INIT_TOTAL_ITEMS;
  itemsPerPage: number = INIT_ITEMS_PER_PAGE;

  constructor(
    private webAPI: WebAPIService,
    public authS: AuthService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private router: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.user = this.authS.GetUser() as AuthUser;

    var today = new Date();
    this.currentDate =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate() +
      ' ' +
      today.getHours() +
      ':' +
      today.getMinutes() +
      ':' +
      today.getSeconds();
    this.status_id = this.router.snapshot.params.status_id;

    this.searchForm = this.formBuilder.group({
      state: [this.status_id ? this.status_id : '0'],
      user: [
        {
          value:
            this.authS.user.roles[0].pivot.role_id == 3
              ? this.authS.user.roles[0].pivot.id
              : '0',
          disabled: this.authS.user.roles[0].pivot.role_id == 3,
        },
      ],
      priority: ['0'],
      date: ['0'],
      id: '',
      names: '',
    });

    var servObj = new ServiceObject('status');
    this.webAPI
      .GetAction(servObj)
      .then((x) => {
        servObj = <ServiceObject>x;
        this.status = x.data.status;
      })
      .catch((x) => {
        throw x.message;
      });

    var servObj = new ServiceObject('user');
    this.webAPI
      .GetAction(servObj)
      .then((x) => {
        servObj = <ServiceObject>x;
        this.users = x.data.users;
      })
      .catch((x) => {
        throw x.message;
      });

    var servObj = new ServiceObject('priority');
    this.webAPI
      .GetAction(servObj)
      .then((x) => {
        servObj = <ServiceObject>x;
        this.priority = x.data.priority;
      })
      .catch((x) => {
        throw x.message;
      });

    this.GetTickets();
  }

  GetTickets() {
    var paramsMain = new HttpParams();
    this.tickets = [];

    paramsMain = paramsMain.append('special', 2);

    paramsMain = paramsMain.append('pagination', true);
    paramsMain = paramsMain.append('current_page', this.currentPage);
    paramsMain = paramsMain.append('per_page', this.itemsPerPage);

    if (this.authS.user.roles[0].pivot.role_id == 3)
      paramsMain = paramsMain.append(
        'user_role_id',
        this.authS.user.roles[0].pivot.id
      );

    if (this.authS.user.roles[0].pivot.role_id == 2)
      paramsMain = paramsMain.append(
        'group',
        this.authS.user.user_role[0].user_role_group[0].group_id
      );

    if (this.searchForm.controls.state.value > 0)
      paramsMain = paramsMain.append(
        'status_id',
        this.searchForm.controls.state.value
      );

    if (this.searchForm.controls.priority.value > 0)
      paramsMain = paramsMain.append(
        'ticket_priority_id',
        this.searchForm.controls.priority.value
      );

    if (this.searchForm.controls.user.value > 0)
      paramsMain = paramsMain.append(
        'user_role_id',
        this.searchForm.controls.user.value
      );

    if (this.searchForm.controls.id.value > 0)
      paramsMain = paramsMain.append('id', this.searchForm.controls.id.value);

    if (this.searchForm.controls.names.value.length > 0)
      paramsMain = paramsMain.append(
        'names',
        this.searchForm.controls.names.value
      );

    var servObj = new ServiceObject('ticket');

    this.webAPI
      .GetAction(servObj, paramsMain)
      .then((x) => {
        const response = x as TicketResponse;

        servObj = <ServiceObject>response;

        if (this.searchForm.controls.state.value > 0) {
          response.data.tickets.data.forEach((ticket: Ticket) => {
            if (this.searchForm.controls.state.value == 100)
              this.tickets.push(ticket);
            else if (
              ticket.statuses[ticket.statuses.length - 1].id ==
              this.searchForm.controls.state.value
            )
              this.tickets.push(ticket);
          });
        } else {
          this.tickets = response.data.tickets.data;
        }

        this.totalItems = response.data.tickets.total;
      })
      .catch((x) => {
        throw x.message;
      });
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.GetTickets();
  }

  onItemsPerPageChange(event: any): void {
    this.itemsPerPage = +event.target.value;
    this.currentPage = INIT_CURRENT_PAGE; // Reset to first page whenever items per page changes
    this.GetTickets();
  }

  open(item: any) {
    const modalRef = this.modalService.open(TicketsFormComponent, {
      size: 'lg',
      backdrop: 'static',
    });
    modalRef.componentInstance.item = item;
    modalRef.componentInstance.currentDate = this.currentDate;
    modalRef.result.then((result) => {
      if (result) this.GetTickets();
    });
  }

  createTicket() {
    const modalRef = this.modalService.open(TicketFormComponent, {
      size: 'lg',
      backdrop: 'static',
    });
  }

  totalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  applyFilters() {
    this.currentPage = INIT_CURRENT_PAGE;
    this.totalItems = INIT_TOTAL_ITEMS;
    this.itemsPerPage = INIT_ITEMS_PER_PAGE;

    this.GetTickets();
  }

  getPagesToShow(): (number | null)[] {
    const pagesToShow: (number | null)[] = [];
    const totalPages = this.totalPages();

    for (let i = 1; i <= Math.min(3, totalPages); i++) {
      pagesToShow.push(i);
    }

    if (this.currentPage > 4) {
      pagesToShow.push(null);
      for (
        let i = this.currentPage - 1;
        i <= Math.min(this.currentPage + 1, totalPages);
        i++
      ) {
        pagesToShow.push(i);
      }
    } else {
      for (let i = 4; i <= Math.min(6, totalPages); i++) {
        pagesToShow.push(i);
      }
    }

    if (this.currentPage < totalPages - 3) {
      pagesToShow.push(null);
      for (let i = totalPages - 2; i <= totalPages; i++) {
        pagesToShow.push(i);
      }
    }

    return pagesToShow;
  }
}
